/*eslint-disable*/
import React from "react";
import { BrowserRouter } from "react-router-dom";
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  NavLink,
} from "reactstrap";
import { Link } from "react-scroll";

import logo from "../assets/img/bg1.png";

function CodigNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const hideCollapseHandler = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(false);
  };

  return (
    <BrowserRouter>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className="bg-white fixed-top" expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand tag={Link} to="/">
              <img
                className="logoNav"
                src={logo}
                onClick={() => {
                  document.documentElement.classList.remove("nav-open");
                  setCollapseOpen(false);
                  window.location.reload(false);
                }}
              ></img>
            </NavbarBrand>
            <div className="navbar-button">
              <button
                onClick={() => {
                  document.documentElement.classList.toggle("nav-open");
                  setCollapseOpen(!collapseOpen);
                }}
                aria-expanded={collapseOpen}
                className="navbar-toggler"
              >
                <span className="navbar-toggler-bar top-bar"></span>
                <span className="navbar-toggler-bar middle-bar"></span>
                <span className="navbar-toggler-bar bottom-bar"></span>
              </button>{" "}
            </div>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav
              className="mx-auto navTranslate"
              id="ceva"
              navbar
              style={{ fontSize: "14px" }}
            >
              <NavItem>
                <Link
                  style={{
                    fontSize: "12px",
                    marginRight: "20px",
                    cursor: "pointer",
                  }}
                  className="navigationLink"
                  activeClass="activeLink"
                  onClick={hideCollapseHandler}
                  smooth
                  spy
                  offset={-70}
                  to="solutions"
                >
                  SOLUTIONS
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  style={{
                    fontSize: "12px",
                    marginRight: "20px",
                    cursor: "pointer",
                  }}
                  className="navigationLink"
                  activeClass="activeLink"
                  onClick={hideCollapseHandler}
                  smooth
                  spy
                  offset={-70}
                  to="aboutUs"
                >
                  ABOUT US
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  style={{
                    fontSize: "12px",
                    marginRight: "20px",
                    cursor: "pointer",
                  }}
                  className="navigationLink"
                  activeClass="activeLink"
                  onClick={hideCollapseHandler}
                  smooth
                  spy
                  offset={-70}
                  to="ourWork"
                >
                  OUR WORK{" "}
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  style={{
                    fontSize: "12px",
                    marginRight: "20px",
                    cursor: "pointer",
                  }}
                  className="navigationLink"
                  activeClass="activeLink"
                  onClick={hideCollapseHandler}
                  smooth
                  spy
                  offset={-70}
                  to="contactUs"
                >
                  CONTACT
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </BrowserRouter>
  );
}
export default CodigNavbar;
