import React from "react";

import { Container, Row, Col } from "reactstrap";

function Solutions() {
  return (
      <div className="features-3 solutions" id="solutions">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="10">
              <h4 className="title text-center text-headers">[ SOLUTIONS ]</h4>
              <hr class="codigHr" />             
               <h4 className="description" style={{marginBottom: "40px"}}>
                We have advanced knowledge from diverse fields which allows us
                to meet your specific requirements. Our software house offers a
                variety of services that can improve your company's workflow and
                efficiency.{" "}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <div className="info info-hover">
                <div className="icon icon-primary icon-circle">
                  <i
                    className="now-ui-icons objects_spaceship"
                    style={{ color: "rgb(213, 113, 0)" }}
                  ></i>
                </div>
                <h4 className="info-title" style={{ color: "white" }}>
                  CUSTOM SOFTWARE PROJECTS
                </h4>
                <p className="description">
                  We see beyond the horizon and hear your innovative ideas; it
                  is our aim to bring them to life. We are committed to making
                  your wishes a reality and accelerating their progress. Allow
                  our skilled professionals to guide you through the process of
                  creating the desirable solution for your business.
                </p>
              </div>
            </Col>
            <Col md="4">
              <div className="info info-hover">
                <div className="icon icon-primary icon-circle">
                  <i
                    className="now-ui-icons business_globe"
                    style={{ color: "rgb(213, 113, 0)" }}
                  ></i>
                </div>
                <h4 className="info-title" style={{ color: "white" }}>
                  WEB AND MOBILE APP DEVELOPMENT
                </h4>
                <p className="description">
                  We deliver the expected results whether you have a simple
                  sketch for an app which needs to be brought to life or one
                  that appears to require refreshment and scaling. We can
                  design, build, test, deploy, and maintain web and mobile
                  applications for small and large businesses alike.
                </p>
              </div>
            </Col>
            <Col md="4">
              <div className="info info-hover">
                <div className="icon icon-primary icon-circle">
                  <i
                    className="now-ui-icons business_briefcase-24"
                    style={{ color: "rgb(213, 113, 0)" }}
                  ></i>
                </div>
                <h4 className="info-title" style={{ color: "white" }}>
                  PROFESSIONAL TECHNOLOGY STAFFING
                </h4>
                <p className="description">
                  We are prepared to provide appropriate assistance to ensure
                  project success. If you intend to expand your existing team,
                  we can provide you with satisfactory performance. We can find
                  an ideal solution for you whether you need to form a team
                  temporarily or on a long-term basis.{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
  );
}

export default Solutions;
  