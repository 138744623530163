import React from "react";

import {
  Card,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

function OurWork() {
  const [pills, setPills] = React.useState("1");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
      <div
        id="ourWork"
        className="features-8 section-image ourWorkSpace"
        style={{
          backgroundImage: "url(" + require("assets/img/back-5.png") + ")",
          backgroundPosition: "center",
        }}
      >
        <Container>
          <Row>
            <Col md="12">
              <h4 className="title text-center text-headers" style={{marginTop: "60px"}}>[ OUR WORK ]</h4>
              <hr class="codigHr" />
                            <div className="nav-align-center">
                <Nav
                  className="nav-pills-warning nav-pills-just-icons pillsCenter"
                  pills
                  role="tablist"
                >
                  <NavItem>
                    <NavLink
                      onClick={(e) => {
                        setPills("1");
                      }}
                      className={pills === "1" ? "active" : ""}
                      role="tablist"
                      style={{cursor: "pointer"}}
                    ></NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      onClick={(e) => {
                        setPills("2");
                      }}
                      className={pills === "2" ? "active" : ""}
                      role="tablist"
                      style={{cursor: "pointer"}}
                    ></NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      onClick={(e) => {
                        setPills("3");
                      }}
                      className={pills === "3" ? "active" : ""}
                      role="tablist"
                      style={{cursor: "pointer"}}
                    ></NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      onClick={(e) => {
                        setPills("4");
                      }}
                      className={pills === "4" ? "active" : ""}
                      role="tablist"
                      style={{cursor: "pointer"}}
                    ></NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      onClick={(e) => {
                        setPills("5");
                      }}
                      className={pills === "5" ? "active" : ""}
                      role="tablist"
                      style={{cursor: "pointer"}}
                    ></NavLink>
                  </NavItem>
                </Nav>
              </div>
              <TabContent className="gallery" activeTab={"pills" + pills}>
                <TabPane tabId="pills4">
                  <Row>
                    <Col className="ml-auto mr-auto" md="6">
                      <Card
                        className="card-background card-background-product card-raised ourWorkImage"
                        style={{
                          backgroundImage:
                            "url(" + require("assets/img/kids.jpg") + ")",
                        }}
                      >
                        <CardBody></CardBody>
                      </Card>
                    </Col>
                    <Col md="5" className="ourWorkRow">
                      <div className="info info-horizontal ourWorkInfo">
                        <div className="icon icon-warning">
                          <i className="now-ui-icons arrows-1_minimal-right"></i>
                        </div>
                        <div className="description">
                          <h5 className="info-title" style={{marginLeft: "0px"}}>
                            INTELLIGENT TRANSMISSION
                          </h5>
                          <p className="description">
                            Kisdsup is an electronic system which was created to
                            support the communication between parents and
                            kindergartens.
                          </p>
                        </div>
                      </div>
                      <div className="info info-horizontal ourWorkInfo">
                        <div className="icon icon-warning">
                          <i className="now-ui-icons arrows-1_minimal-right"></i>
                        </div>
                        <div className="description">
                          <h5 className="info-title" style={{marginLeft: "0px"}}>EFFICIENT WORKFLOW</h5>
                          <p className="description">
                            We simplified the everyday work around the
                            management by implementing features such as veryfing
                            the child's attendance or updating the formalities
                            via the mobile app.
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="pills1">
                  <Row>
                    <Col className="ml-auto mr-auto" md="6">
                      <Card
                        className="card-background card-background-product card-raised ourWorkImage"
                        style={{
                          backgroundImage:
                            "url(" + require("assets/img/edu.jpg") + ")",
                        }}
                      >
                        <CardBody></CardBody>
                      </Card>
                    </Col>
                    <Col md="5" className="ourWorkRow">
                      <div className="info info-horizontal ourWorkInfo">
                        <div className="icon icon-danger">
                          <i className="now-ui-icons arrows-1_minimal-right"></i>
                        </div>
                        <div className="description">
                          <h5 className="info-title" style={{marginLeft: "0px"}}>FLEXIBLE SOLUTIONS</h5>
                          <p className="description">
                            EduLyke is a platform that contains educational
                            online courses for students, parents, and teachers,
                            who can move around the website and learn remotely
                            anytime from anywhere.
                          </p>
                        </div>
                      </div>
                      <div className="info info-horizontal ourWorkInfo">
                        <div className="icon icon-danger">
                          <i className="now-ui-icons arrows-1_minimal-right"></i>
                        </div>
                        <div className="description">
                          <h5 className="info-title" style={{marginLeft: "0px"}}>CONTINUOUS MAINTENANCE</h5>
                          <p className="description">
                            We enabled saving progress after each lesson as well
                            as sending inquiries to a chatbot. Learners can
                            complete quizzes and receive certificates at the
                            end.
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="pills5">
                  <Row>
                    <Col className="ml-auto mr-auto" md="6">
                      <Card
                        className="card-background card-background-product card-raised ourWorkImage"
                        style={{
                          backgroundImage:
                            "url(" + require("assets/img/csr.jpg") + ")",
                        }}
                      >
                        <CardBody></CardBody>
                      </Card>
                    </Col>
                    <Col md="5" className="ourWorkRow">
                      <div className="info info-horizontal ourWorkInfo">
                        <div className="icon icon-info">
                          <i className="now-ui-icons arrows-1_minimal-right"></i>
                        </div>
                        <div className="description">
                          <h5 className="info-title" style={{marginLeft: "0px"}}>
                            CUSTOMER ORIENTED
                          </h5>
                          <p className="description">
                          We have a history of supporting non-governmental organisations with their CSR projects. Our team has developed diverse mobile and web applications to meet each of their specific needs.
                          </p>
                        </div>
                      </div>
                      <div className="info info-horizontal ourWorkInfo">
                        <div className="icon icon-info">
                          <i className="now-ui-icons arrows-1_minimal-right"></i>
                        </div>
                        <div className="description">
                          <h5 className="info-title" style={{marginLeft: "0px"}}>TECHNOLOGICAL ASSISTANCE</h5>
                          <p className="description">
                          During our collaborative work, we've implemented a wide range of mechanisms and features, such as interactive online courses or a continuous flow of communication between users via the real-time chat option.
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="pills3">
                  <Row>
                    <Col className="ml-auto mr-auto" md="6">
                      <Card
                        className="card-background card-background-product card-raised ourWorkImage"
                        style={{
                          backgroundImage:
                            "url(" + require("assets/img/bloxi.jpg") + ")",
                        }}
                      >
                        <div style={{background: "#0f0f0f4d", position: "absolute", height: "100%", width: "100%"}}></div>
                        <CardBody></CardBody>
                      </Card>
                    </Col>
                    <Col md="5" className="ourWorkRow">
                      <div className="info info-horizontal ourWorkInfo">
                        <div className="icon icon-info">
                          <i className="now-ui-icons arrows-1_minimal-right"></i>
                        </div>
                        <div className="description">
                          <h5 className="info-title" style={{marginLeft: "0px"}}>
                            CLEVER FUN
                          </h5>
                          <p className="description">
                          BLOXI is a mobile application designed to challenge users and stimulate their brains into thinking quick and bold.                          </p>
                        </div>
                      </div>
                      <div className="info info-horizontal ourWorkInfo">
                        <div className="icon icon-info">
                          <i className="now-ui-icons arrows-1_minimal-right"></i>
                        </div>
                        <div className="description">
                          <h5 className="info-title" style={{marginLeft: "0px"}}>COLORFUL RELAXATION</h5>
                          <p className="description">
                          We've found an idea for how to let people play in a wise way, which was accomplished by implementing specific mechanisms into the app—figuring out how to fit the blocks.
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="pills2">
                  <Row>
                    <Col className="ml-auto mr-auto" md="6">
                      <Card
                        className="card-background card-background-product card-raised ourWorkImage"
                        style={{
                          backgroundImage:
                            "url(" + require("assets/img/andromeda.jpg") + ")",
                        }}
                      >
                        <div style={{background: "rgba(15, 15, 15, 0.2)", position: "absolute", height: "100%", width: "100%"}}></div>
                        <CardBody></CardBody>
                      </Card>
                    </Col>
                    <Col md="5" className="ourWorkRow">
                      <div className="info info-horizontal ourWorkInfo">
                        <div className="icon icon-info">
                          <i className="now-ui-icons arrows-1_minimal-right"></i>
                        </div>
                        <div className="description">
                          <h5 className="info-title" style={{marginLeft: "0px"}}>
                            DESIGNED HARMONY 
                          </h5>
                          <p className="description">
                          Andromeda is a software system created to help hospitals and medical centres contain the maintenance work.                          </p>
                        </div>
                      </div>
                      <div className="info info-horizontal ourWorkInfo">
                        <div className="icon icon-info">
                          <i className="now-ui-icons arrows-1_minimal-right"></i>
                        </div>
                        <div className="description">
                          <h5 className="info-title" style={{marginLeft: "0px"}}>SMART MANAGEMENT</h5>
                          <p className="description">
                          The solution enabled institutions to take control of the warehouse system, surgical software, orders and deliveries, reports and results, staff working time, barcodes, documentation and contractors.                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
  );
}

export default OurWork;
