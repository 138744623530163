import React from "react";
import { Card, Container, Row, Col } from "reactstrap";
function CodigAboutUs() {
  return (
      <div
        className="features-8 section-image"
        id="aboutUs"
        style={{
          backgroundImage:
            "url(" + require("assets/img/background-3-part1.png") + ")",
          backgroundPosition: "center",
          backgroundSize: "100% 100%",
          marginTop: "-30px",
        }}
      >
        <Col className="ml-auto mr-auto text-center" md="9">
          <h4
            className="title text-center text-headers"
            style={{ paddingTop: "80px" }}
          >
            [ ABOUT US ]
          </h4>

          <hr class="codigHr" />
                    <h4 className="description" style={{ marginBottom: "60px", color: "#e3e1e1" }}>
            Codigmatic is a team of skilled professionals with years of
            expertise whose mission is to escalate your ideas into the universe.
            We take your business seriously, respect your opinions, and
            thoughtfully incorporate your needs into our work. Our company is
            committed to each project, approaching them with consistency and
            professionalism can give you guidance on how to reach the right
            audiences in the most proficient way.
          </h4>
        </Col>
        <Container>
          <Row>
            <Col md="6" lg="3">
              <Card className="cardShow cardAbout">
                <div className="info text-center aboutUsInfo">
                  <div className="icon aboutIcon">
                    <i
                      className="now-ui-icons business_bulb-63"
                      style={{ marginTop: "-30px" }}
                    ></i>
                  </div>
                  <h4 className="info-title" style={{marginTop: "5px"}}>KNOWLEDGE</h4>
                  <p className="description descriptionSection">
                    We are constantly researching the latest trends, which allow
                    us to adapt to new solutions and use the most up-to-date
                    technologies. We pride ourselves on our extensive
                    technological wisdom, and we can assure you'll receive the
                    best resources possible.
                  </p>{" "}
                </div>
              </Card>
            </Col>
            <Col md="6" lg="3">
              <Card className="cardShow cardAbout">
                <div className="info text-center aboutUsInfo">
                  <div className="icon aboutIcon">
                    <i
                      className="now-ui-icons ui-2_chat-round"
                      style={{ marginTop: "-30px" }}
                    ></i>
                  </div>
                  <h4 className="info-title" style={{marginTop: "5px"}}>TRUST</h4>
                  <p className="description descriptionSection" style={{padding: "0px 10px"}}>
                    Clear and consistent communication is one of our core
                    features. We believe that through transparency and asking
                    the right questions we are able to build foundations of your
                    long lasting success. What we preach is that mutual trust
                    makes up a great partnership.{" "}
                  </p>
                </div>
              </Card>
            </Col>
            <Col md="6" lg="3">
              <Card className="cardShow cardAbout">
                <div className="info text-center aboutUsInfo">
                  <div className="icon aboutIcon">
                    <i
                      className="now-ui-icons sport_trophy"
                      style={{ marginTop: "-30px" }}
                    ></i>
                  </div>
                  <h4 className="info-title" style={{marginTop: "5px"}}>AMBITION</h4>
                  <p className="description descriptionSection" style={{padding: "2px"}}>
                    We enjoy working on inspiring projects and want you to
                    accelerate by meeting your expectations, combining them with
                    our insight. We are not scared of innovation; we approach it
                    as a challenge, and being able to work on such solutions
                    only motivates us to keep aiming high.
                  </p>
                </div>
              </Card>
            </Col>
            <Col md="6" lg="3">
              <Card className="cardShow cardAbout">
                <div className="info text-center aboutUsInfo">
                  <div className="icon aboutIcon">
                    <i
                      className="now-ui-icons design-2_ruler-pencil"
                      style={{ marginTop: "-30px" }}
                    ></i>
                  </div>
                  <h4 className="info-title" style={{marginTop: "5px"}}>FLEXIBILITY</h4>
                  <p className="description descriptionSection">
                    You can rely on us to deliver the expected results because
                    we can effortlessly adjust to each individual without a
                    problem. Our strength lies in our willingness to work on
                    out-of-the-box ideas from any field. We take pride in
                    adapting to the requested suggestions.
                  </p>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
        className="features-4"
        style={{
          backgroundImage: "url(" + require("assets/img/back-4.jpg") + ")",
          backgroundPosition: "top",
          backgroundSize: "cover",
        }}
      >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h4 className="title text-center text-headers">
                [ TECHNOLOGIES ]
              </h4>
              <hr class="codigHr" />
                            <h4 className="description technoDescription">
                Our team of professional developers demonstrate advanced
                knowledge of various mobile and web software technologies which
                allows us to meet each of your unique requirements.
              </h4>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Card
                className="card-background card-raised technologyCard"
                data-background-color=""
                style={{
                  height: "350px",
                  backgroundImage:
                    "url(" + require("assets/img/tech1.png") + ")",
                }}
              >
                <div className="info">
                  <div className="icon icon-white"></div>
                </div>
              </Card>
            </Col>
            <Col md="6">
              <Card
                className="card-background card-raised technologyCard technologyCard2"
                data-background-color=""
                style={{
                  height: "350px",
                  backgroundImage:
                    "url(" + require("assets/img/tech2.png") + ")",
                }}
              >
                <div className="info"></div>
              </Card>
            </Col>

          </Row>
        </Container>
      </div>
      </div>
  );
}
export default CodigAboutUs;
