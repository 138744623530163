import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';

function CodigContact() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [form, setForm] = React.useState({})

  const sendMessage = () => {
    axios.post("https://netluck.pl:9030/codigmatic/mail", form).then(() => {
      toast.success('Wiadomość wysłana');

      setForm({});

    }).catch(() => {
      toast.error('Wystąpił problem - prosimy spróbować później');
    })
  }

  const formChange = (e) => {
    const {name, value} = e.target
    const tmp = {...form}
    tmp[name] = value
    setForm(tmp)
  }

  return (
      <div className="cd-section" id="contactUs">
        <div
          className="contactus-1 section-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/background12.jpg") + ")",
          }}
        >
          <Container>
            <Row>
              <Col md="5">
                <h4 className="title text-center text-headers">
                  [ REACH OUT ]
                </h4>
                <hr class="codigHr" />
                   <h4 className="description">
                  Any inquiries regarding the journey? Send them our way, we
                  promise they won't get lost in the void.
                </h4>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons location_pin"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title" style={{marginLeft: "0px"}}>Find us at the office</h4>
                    <p className="description" style={{color: "rgb(236, 234, 234)"}}>
                      Lelewela 6A <br></br>
                      61-409 Poznań <br></br>
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons tech_mobile"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title" style={{marginLeft: "0px"}}>Contact us</h4>
                    <p className="description" style={{color: "rgb(236, 234, 234)"}}>
                    office@codigmatic.tech
                    </p>
                  </div>
                </div>
              </Col>
              <Col className="ml-auto mr-auto" md="5">
                <Card className="card-contact card-raised">
                  <Form id="contact-form1" method="post" role="form">
                    <CardHeader className="text-center">
                      <CardTitle tag="h4">Write to Us</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="pr-2" md="6">
                          <label>First name</label>
                          <InputGroup
                            className={firstFocus ? "input-group-focus" : ""}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="now-ui-icons users_circle-08"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              aria-label="First Name..."
                              autoComplete="given-name"
                              placeholder="First Name..."
                              type="text"
                              name="firstname"
                              onChange={formChange}
                              onFocus={() => setFirstFocus(true)}
                              onBlur={() => setFirstFocus(false)}
                            ></Input>
                          </InputGroup>
                        </Col>
                        <Col className="pl-2" md="6">
                          <FormGroup>
                            <label>Last name</label>
                            <InputGroup
                              className={lastFocus ? "input-group-focus" : ""}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="now-ui-icons text_caps-small"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                aria-label="Last Name..."
                                autoComplete="family-name"
                                placeholder="Last Name..."
                                type="text"
                                name="lastname"
                                onChange={formChange}
                                onFocus={() => setLastFocus(true)}
                                onBlur={() => setLastFocus(false)}
                              ></Input>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup>
                        <label>Email address</label>
                        <InputGroup
                          className={emailFocus ? "input-group-focus" : ""}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons ui-1_email-85"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            autoComplete="email"
                            placeholder="Email Here..."
                            type="email"
                            name="email"
                            onChange={formChange}
                            onFocus={() => setEmailFocus(true)}
                            onBlur={() => setEmailFocus(false)}
                          ></Input>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <label>Your message</label>
                        <Input
                          id="message"
                          name="message"
                          onChange={formChange}
                          rows="6"
                          type="textarea"
                        ></Input>
                      </FormGroup>
                      <Row>
                        <Col md="6">
                          <Button
                            className="btn-round pull-right buttonContact"
                            onClick={sendMessage}
                          >
                            Send Message
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Toaster/>
      </div>
  );
}
export default CodigContact;
