import React from "react";

import { Container } from "reactstrap";

function CodigFooter() {
  return (
      <footer className="footer footer-white footer-big">
        <Container>
        <hr class="codigHr" />
                  <div className="copyright pull-center">
            Copyright © {new Date().getFullYear()} Codigmatic All Rights
            Reserved.
          </div>
        </Container>
      </footer>
  );
}

export default CodigFooter;
