import React from "react";

import Solutions from "./Solutions";
import CodigAboutUs from "./CodigAboutUs";
import CodigContact from "./CodigContact";
import CodigFooter from "./CodigFooter";
import OurWork from "./OurWork";
import CodigHeader from "./CodigHeader";
import CodigNavbar from "./CodigNavbar";

function Main() {
  return (
    <>
      <CodigNavbar />
      <CodigHeader />
      <Solutions />
      <CodigAboutUs />
      <OurWork />
      <CodigContact />
      <CodigFooter />
    </>
  );
}

export default Main;
