import React from "react";

import { Container, Row, Col } from "reactstrap";

function CodigHeader() {
  return (
      <div className="page-header header-filter">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/back-1.jpg") + ")",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        ></div>
        <Container>
          <Row>
            <Col
              className="ml-auto  headerText"
              style={{ marginTop: "150px"}}
              md="7"
            >
              <h1
                className="title animate-charcter"
                style={{ fontSize: "58px" }}
              >
                Skyrocket with us
              </h1>
              <h4 className="description" style={{ fontSize: "25px", fontWeight: "400" }}>
                <span className="codigText">Codigmatic</span> is here to become
                your technology partner providing{" "}
                <span className="codigText">solutions</span> from the very first
                flicker of your concept and{" "}
                <span className="codigText">collaborating</span> all the way to
                the launch.
              </h4>
            </Col>
          </Row>
        </Container>
      </div>
  );
}
export default CodigHeader;
